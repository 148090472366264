@use '@angular/material' as mat;



@import '@angular/material/theming';

// always include only once per project
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();


$primary: mat.define-palette(mat.$indigo-palette);
$primary-color: mat.define-palette(mat.$grey-palette);

@import "vars";

@import '@angular/material/prebuilt-themes/deeppurple-amber';


@import "shared";
// indigo-pink.css
// purple-green.css
// pink-bluegrey.css
// deeppurple-amber.css

/* width */

